@let hasErrors = control() | hasErrors;
<app-styled-input>
  <div class="wrapper">
    <app-icon [src]="'/images/icons/search.svg'" [size]="{size: IconSize['w-24']}" (click)="action.emit()"></app-icon>
    <input #input type="text" [placeholder]="'search.search' | translate"
           [ngClass]="{error: hasErrors}" [formControl]="control()"
           (keyup)="handleEnterClicked($event)"
           (input)="!control().untouched && control().markAsUntouched()">
  </div>

  @if (hasErrors) {
    <app-error-message [errors]="control().errors"></app-error-message>
  }
</app-styled-input>
