import {ChangeDetectionStrategy, Component, ElementRef, output, viewChild} from '@angular/core';
import {BaseInputComponent} from '@shared/components/form/inputs/base-input/base-input.component';
import {ErrorMessageComponent} from '@shared/components/form/styled-input/error-message/error-message.component';
import {HasErrorsPipe} from '@shared/components/form/has-errors.pipe';
import {ReactiveFormsModule} from '@angular/forms';
import {StyledInputComponent} from '@shared/components/form/styled-input/styled-input.component';
import {NgClass} from '@angular/common';
import {IconComponent, IconSize} from '@shared/components/ui/icons/icon/icon.component';
import {TranslatePipe} from '@shared/pipes/translate.pipe';

@Component({
  selector: 'app-search-input',
  imports: [
    ErrorMessageComponent,
    HasErrorsPipe,
    ReactiveFormsModule,
    StyledInputComponent,
    NgClass,
    IconComponent,
    TranslatePipe
  ],
  templateUrl: './search-input.component.html',
  styleUrl: './search-input.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SearchInputComponent extends BaseInputComponent {
  input = viewChild<ElementRef<HTMLInputElement>>('input');

  action = output<void>();

  handleEnterClicked(event: KeyboardEvent) {
    if(event.key === 'Enter') {
      event.preventDefault();
      this.action.emit();
    }
  }
  protected readonly IconSize = IconSize;
}
